export default {
  LIST_COMBOBOX_BUSINESS: 'Bussiness/get-combobox-bussiness',
  LIST_INSPECTION_TRAINING: 'LicenseTraining/list-license-training',
  ACCEPT_LICENSE: 'LicenseTraining/approve-license-training',
  REJECT_LICENSE: 'LicenseTraining/reject-license-training',
  EXTEND_LICENSE: 'LicenseTraining/extend-license-training',
  RENEW_LICENSE: 'LicenseTraining/renew-license-training',
  REVOKE_LICENSE: 'LicenseTraining/revoke-license-training',
  LIST_FILE_DOWNLOAD: 'LicenseTraining/list-license-training-file',
  DELETE_INSPECTION_TRAINING: 'LicenseTraining/delete-license-trainings',
  LIST_FILE_REQUIRES: 'LicenseTraining/list-file-requires',
  UPDATE_FILE_REQUIRES: 'LicenseTraining/update-file-require',
  DOWNLOAD_FILE_REQUIRES: 'LicenseTraining/download-file-require',
}
